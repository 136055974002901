@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    body.admin-panel {
        @apply bg-[#dedff1]
    }
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .button {
        @apply border rounded-lg font-bold text-center py-2 px-4 inline-block;
    }

    .dark_box {
        background: linear-gradient(180deg, rgba(187, 176, 235, 0.1) 0%, rgba(135, 119, 204, 0) 100%), rgba(84, 67, 163, 0.8);
        backdrop-filter: blur(17.5px);
        color: #fff;
    }
    .title-base {
        @apply font-semibold font-inter
    }
    .title-3xl {
        @apply lg:text-3xl text-2xl font-bold font-inter
    }
    .title-2xl {
        @apply lg:text-2xl text-xl font-bold font-inter
    }
    .title-xl {
        @apply lg:text-xl text-lg font-bold font-inter
    }
    .title-lg {
        @apply text-lg font-bold font-inter
    }
    .title-md {
        @apply text-md font-bold font-inter
    }
    .title-sm {
        @apply text-sm font-bold font-inter
    }
    .title-primary {
        @apply font-semibold text-2xl text-primary
    }
    .title-primary-base {
        @apply font-bold text-primary font-inter
    }
    .title-primary-lg {
        overflow-wrap: break-word;
        hyphens: auto;
        @apply title-lg text-primary
    }
    .title-primary-md {
        @apply title-md text-primary
    }
    .title-primary-sm {
        @apply font-bold text-primary font-inter text-[13px]
    }
    .title-white-2xl {
        @apply font-bold font-inter lg:text-2xl text-xl text-white
    }
    .subtitle {
        @apply font-inter text-[#585371]
    }
    .start-box {
        background: linear-gradient(180deg, rgba(187, 176, 235, 0.1) 0%, rgba(135, 119, 204, 0) 100%), rgba(221, 219, 233, 0.8);
        backdrop-filter: blur(17.5px);
        @apply self-center lg:w-1/4 lg:h-[475px] lg:mr-7 lg:mt-0 mt-5 text-primary w-full lg:px-[42px] lg:py-[55px] px-[30px] py-[40px] dark:dark_box;
    }
    .start-box:first-child{
        background: #3A2699 url(./images/bg-box.svg) top right no-repeat;
        background-size: cover;
        color: #fff;
        margin-top: 0;
        @apply lg:px-[54px] dark:bg-white dark:bg-none dark:text-black
    }
    .start-box:first-child span{
        @apply text-secondary dark:text-[#575078]
    }
    .start-box:last-child {
        @apply lg:mr-[-5%] lg:pr-[5%]
    }
    .bg-dark {
        background: #3A2699 url(./images/bg-dark.svg) top right no-repeat;
    }
    .bg-dark-login {
        background: #3A2699 url(./images/bg-login.svg) bottom right no-repeat;
    }
    .bg-loading {
        background: url(./images/bg-loading.svg) bottom right no-repeat;
        background-size: 50vh auto;
    }
    .survey-box {
        @apply lg:w-[calc(50%-17px)] w-full mb-[34px] even:lg:ml-[34px] rounded-[15px] lg:p-[35px] p-7 relative bg-[var(--category-color-light)];/*lg:h-[167px] */
    }
    
    .survey-box h3{
        @apply text-[var(--category-color)];
    }

    .survey-box .survey-image {
        @apply inline-block lg:w-[98px] lg:h-[98px] lg:mr-[25px] w-[68px] h-[68px] mr-[15px] bg-contain align-middle relative
    }
    .expand-button {
        @apply inline-block w-[36px] h-[36px] leading-[36px] absolute lg:right-[26px] lg:bottom-[26px] right-5 bottom-5 rounded-full after:content-[''] after:w-5 after:h-4 after:block after:m-auto after:bg-[url("./images/arrow.svg")] after:bg-center after:bg-no-repeat
    }
    .survey-box-sub-categories {
        transition: 0.3s all ease-in-out;
        @apply bg-white p-[46px] opacity-0 invisible absolute left-0 top-0 w-full z-[1] after:content-[''] after:w-[2px] after:bottom-[46px] after:left-0 after:top-[46px] after:rounded-sm after:absolute hidden after:opacity-90;
    }
    .survey-conf .survey-box-sub-categories {
        @apply bg-none pb-[140px] after:bottom-[140px] before:content-[''] before:absolute before:w-full before:top-0 before:left-0 before:bottom-[140px] before:bg-white before:z-[-1];
    }
    .survey-box.active .survey-box-sub-categories {
        @apply visible opacity-100 top-[100%] block
    }
    .survey-box-sub-categories:after {
        background: linear-gradient(180deg, rgba(58, 181, 140, 0) 0%, var(--category-color) 48.6%, rgba(58, 181, 140, 0) 100%);
    }
    .survey-box-sub-category .subcategory-image {
        @apply w-[60px] h-[60px] bg-center bg-no-repeat inline-block align-middle rounded-full lg:mr-5 mr-5 relative;
        /*background-image: url('./images/award.svg');*/
    }
    .survey-box-sub-category .question-box {
        @apply mt-4 bg-[#F8F8F8] rounded-xl text-[13px] text-[#696484] italic px-4 py-4
    }
    /*.survey-box-sub-category + .survey-box-sub-category{
       border-top: 1px solid #B3B3B3;
       margin-top: 35px;
       padding-top: 42px;
    }*/
    .step-banner-horizontal {
        @apply  h-[330px] mb-11 w-full bg-contain bg-center bg-no-repeat;
        background-image: url(./images/step-banner-horizontal.png);
    }
    .step-banner-vertical {
        @apply rounded-[20px] lg:h-4/5 lg:w-5/12 w-full lg:bg-contain bg-cover bg-center bg-no-repeat overflow-hidden h-[230px] lg:mb-0 mb-11 2xl:min-h-[75vh] lg:min-h-[90vh] md:h-[60vh];
    }
    .icon-secondary {
        @apply w-[63px] h-[63px] bg-secondary bg-center bg-no-repeat inline-block align-middle rounded-full mr-5;
    }
    .questions-wrapper {
        /*counter-reset: questions-counter 0;*/
        @apply p-0
    }
    .questions-wrapper li {
        /*counter-increment: questions-counter 1;  //before:content-[counter(questions-counter)] */
        @apply before:content-[attr(data-count)]  before:absolute before:bg-[#D1D1D1] before:w-8 before:h-8 before:leading-8 before:text-white before:rounded-full before:text-center before:text-sm before:font-bold before:font-inter before:left-0 before:top-0 relative lg:pl-[70px] lg:mb-10 my-10;
    }
    .questions-wrapper h3{
        @apply title-primary-md mb-6 lg:pl-0 pl-12;
    }
    .dot-select {
        @apply relative flex flex-wrap before:content-[''] before:xl:left-10 before:xl:right-10 before:left-8 before:right-8 before:absolute before:h-[1px] before:bg-[#D1D1D1] before:top-2
    }
    .dot-select .dot-select-option {
        @apply w-1/6 font-inter sm:text-sm text-[11px] text-center tracking-[.04em] text-[#585371] before:content-[''] before:w-[18px]  before:h-[18px] before:block before:mx-auto before:mb-3 before:rounded-full before:bg-[#D1D1D1] z-[1] cursor-pointer
    }
    .dot-select .dot-select-option.active:before{
        @apply bg-secondary 
    }
    
    .textarea {
        @apply bg-[#f4f4f4] block w-full rounded-[5px] h-[133px] p-5
    }
    .textarea:focus, .textarea:active, .textarea:hover {
        outline: none;
        box-shadow: none;
    }
    .icon-confetti {
        @apply w-[120px] h-[120px] bg-center bg-no-repeat inline-block;
        background-image: url('./images/confetti.svg');
    }
    .box-primary {
        @apply bg-primary text-white p-6 pl-9 rounded-[15px]
    }
    .icon-light-green, .icon-light-blue, .icon-light-brown, .icon-dark-purple, .icon-light-purple, .icon-dark-red {
        @apply w-11 h-11 inline-block align-middle rounded-full relative after:content-[''] after:absolute after:w-8 after:h-8 after:bg-center after:bg-no-repeat after:bg-contain after:left-[6px] after:top-[6px]
    }
    .icon-strength-weakness {
        @apply w-11 h-11 inline-block align-top rounded-full relative
    }
    .icon-strength-weakness img {
        @apply absolute w-8 h-8 left-[6px] top-[6px]
    }
    .icon-lg{
        @apply w-[70px] h-[70px] after:w-10 after:h-10 after:left-[15px] after:top-[15px] inline-block align-middle bg-no-repeat bg-center rounded-full relative after:content-[''] after:absolute after:bg-center after:bg-no-repeat after:bg-contain
    }
    .icon-border-rounded, .icon-light-green.icon-border-rounded, .icon-light-blue.icon-border-rounded, .icon-light-brown.icon-border-rounded, .icon-dark-purple.icon-border-rounded, .icon-light-purple.icon-border-rounded, .icon-dark-red.icon-border-rounded {
        background-color: transparent!important;
        border: 1px solid;
        @apply after:filter-none border
    }
    .text{
        @apply bg-[#F2F0F8] rounded-[7px] placeholder-[#8A8A8A] py-[10px] px-[15px] text-black focus:outline-none
    }
    .progress-bar {
        @apply bg-[#281A6A] w-full h-3 rounded relative;
    }
    .progress-bar > span{
        @apply bg-white h-3 rounded absolute top-0 left-0 max-w-full;
    }
    .progress-bar .self-assess-mark {
        @apply w-1 -top-[2px] -bottom-[2px] h-auto bg-secondary -ml-[2px];
    }
    .text-question-box {
        @apply mb-3
    }
    .text-question-box h3{
        @apply bg-[#F8F8F8] lg:px-8 pl-6 pr-11 py-6 rounded-[14px] mb-3 title-primary-md relative
    }
    .text-question-body {
        display: none;
    }
    .text-question-box.active .text-question-body {
        display: flex;
    }
    .box-secondary {
        @apply text-primary w-full lg:px-[93px] lg:py-10 px-[30px] py-[30px] rounded-xl bg-secondary;
    }
    .card-title{
        @apply font-inter font-bold lg:text-[30px] text-lg leading-[1.3]
    }
    .content-card {
        background: #F4F3F9 url(./images/bg-content-card.svg) top right no-repeat;
        @apply dark:text-white
    }
    .dark .content-card {
        background: #3A2699 url(./images/bg-content-card-dark.svg) top right no-repeat;
    }
    .card-image {
        @apply block w-full h-[278px] rounded-lg bg-center bg-cover
    }
    .nth-green:nth-child(4n-1), .nth-green:nth-child(4n){
        background-color: #97F5AC;
    }
    .summary-item {
        @apply self-center lg:py-3 lg:px-6 p-2 rounded-lg lg:even:bg-[#97F5AC] /*w-1/2 lg:odd:pr-10 lg:even:pl-10 lg:odd:rounded-l-lg lg:even:rounded-r-lg lg:nth-green*/
    }
    .box-white {
        @apply bg-white rounded-[22px] p-6
    }
    .border-title {
        @apply text-[#281A6A] title-lg pb-6 mb-6 border-b-[1px] border-solid border-[#585371]
    }
    .survey-tabs {
        @apply relative z-0 mt-11 before:content-[''] before:absolute before:left-0 before:top-0 before:w-full before:h-full before:bg-primary before:rounded-[15px] leading-[1.2]
    }
    .survey-tab {
        @apply absolute -z-[2] left-0 bottom-[95%] w-[40%] even:left-[35%] bg-[#C0C0C0] text-primary font-inter pt-3 pb-5 rounded-t-[15px]
    }
    .survey-tab.active {
        @apply -z-[1] bg-primary text-[17px] pt-5 pb-6 text-white
    }
    .modal {
        @apply fixed z-20 top-0 left-0 w-full h-full
    }
    .modal-body {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        @apply fixed top-[50%] left-[50%] max-w-full lg:w-[1200px] w-[90%] -translate-x-[50%] -translate-y-[50%] rounded-[15px] bg-white z-20;
    }
    .modal-overflow {
        @apply overflow-auto max-h-[85vh];
    }
    .modal-inner {
        @apply flex flex-wrap relative after:content-[''] after:w-[2px] after:bottom-[46px] lg:after:left-[50%] after:left-0 after:top-[280px] after:rounded-sm after:absolute after:opacity-90;
    }
    .modal-inner:after {
        background: linear-gradient(180deg, rgba(58, 181, 140, 0) 0%, var(--category-color) 48.6%, rgba(58, 181, 140, 0) 100%);
    }
    .modal .survey-box:nth-last-child(2), .modal .survey-box:last-child {margin-bottom: 0;}
    .modal .close-button {
        @apply absolute lg:-right-12 lg:-top-8 -top-8 -right-5 title-primary-md z-20
    }
    .modal .survey-box-sub-category {
        @apply lg:w-[calc(50%-17px)] w-full p-7 lg:px-[35px] lg:py-5 even:lg:ml-[34px] relative;
    }
    /*.modal .survey-box-sub-category + .survey-box-sub-category {
        @apply before:content-[''] before:top-0 before:left-7 before:right-7 before:h-[1px] before:bg-[#B3B3B3] before:absolute
    }*/
    .modal .overlay {
        @apply fixed z-10 top-0 left-0 w-full h-full bg-[#F2F2F2] bg-opacity-90
    }
    /*.modal .survey-box-sub-category:nth-child(3)::before{display: none;}*/
    .tooltip {
        @apply bg-black bg-opacity-70 absolute text-[11px] text-white py-1 px-2 min-w-[100px] rounded text-center bottom-[100%] left-[50%] -translate-x-[50%] mb-3 opacity-0 invisible transition-opacity;
    }
    .tooltip:after {
        border-width: 8px 4px 0 4px;
        border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
        @apply content-[''] absolute left-[50%] -translate-x-[50%] top-[100%]
    }
    .progress-bar .self-assess-mark:hover .tooltip{
        @apply visible opacity-100
    }
    section.no-results {
        @apply relative before:content-[''] before:absolute before:left-0 before:top-0 before:right-0 before:bottom-0 before:bg-white before:bg-opacity-70 before:z-[1]
    }
    section.no-results > div:first-child, section.no-results > div:nth-child(2){
        @apply relative z-[1]
    }
    .card-content {
        @apply whitespace-normal text-md text-[#585371] dark:text-white font-inter
    }
    .card-content >*:first-child {
        @apply mb-11
    }
    .card-content h1 {
        @apply title-3xl text-primary dark:text-white
    }
    .card-content ol > li{
        @apply text-[#281A6A] dark:text-white title-lg relative pl-24 font-normal lg:mb-11 mb-7
    }
    .card-content ol > li::before {
        content:"Step " counter(list-item);
        @apply border-b-4 border-secondary absolute left-0 -top-[2px] font-bold pb-3
    }
    .card-content ul{
        list-style-type: "- ";
        @apply px-9 py-7 font-bold text-md bg-[#F4F3F9] dark:bg-primary text-[#585371] dark:text-white border-l-4 border-l-secondary lg:max-w-[65%] lg:my-12 my-7
    }
    .card-content ul li+li{
        @apply mt-4
    }
    .card-content p{
        @apply lg:mb-12 mb-7
    }
    .icon-inner-img {
        @apply w-full h-auto left-0 top-0 absolute
    }
    .icon-border-rounded .icon-inner-img {
        @apply w-3/4 left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]
    }
    .registration-modal {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        @apply absolute top-5 left-[50%] lg:p-10 p-6 lg:w-[60%] w-[90%] -translate-x-[50%] rounded-[15px] bg-white z-[1];
    }
    .top-bar {
        @apply xl:p-9 lg:p-7 p-5
    }
    .sidebar, .top-bar {
        @apply bg-[#F8F8FC] bg-opacity-80 rounded-[22px] xl:p-9 lg:p-7 p-5
    }
    .sidebar {
        @apply xl:p-7 lg:p-6 p-4
    }
    .sidebar h3 {
        @apply text-[#A69CD0] mb-4 text-[14px] font-bold
    }
    .sidebar a, .sidebar-mobile a {
        @apply p-[10px] text-primary rounded-[8px] block font-bold
    }
    .sidebar svg{
        @apply inline-block align-middle mr-4 w-4 h-4
    }
    .sidebar a.current, .sidebar-mobile a.current {
        @apply text-white bg-[#7466B6]
    }
    .sidebar a.current:not(.no-stroke) > svg *{
        stroke: white;
    }
    .sidebar a.current.fill > svg *{
        fill: white;
    }
    .sidebar nav > ul > li{
        @apply mb-8
    }
    .sidebar nav > ul > li:last-child{
        @apply mb-0
    }
    .sidebar nav > ul > li:first-child svg{
        @apply w-6 h-6 mr-[10px]
    }
    .top-bar {
        @apply p-6
    }
    input, select {
        @apply bg-white rounded-[10px] py-3 px-6 text-[14px] leading-[20px] placeholder:text-[#686285] outline-primary
    }
    .admin-data-table.analyze td:nth-child(even) input, .modal input, .modal select, .box-white input, .inner-white-box input {
         @apply bg-[#F8F8FC]
     }
    .admin-data-table input {
        @apply lg:py-2 lg:px-4 py-1 px-2
    }
    .modal input, .top-bar select {
        @apply w-full
    }
    .top-bar .button, .box-white .button {
        @apply font-[500] text-primary rounded-[10px] py-[11px] px-6 min-w-[100px] hover:bg-primary hover:text-white
    }
    .admin-data-table thead th{
        @apply font-[500] text-center tracking-wider text-primary pb-4
    }
    .modal .admin-data-table thead th {
        @apply pt-4
    }
    .admin-data-table tbody td {
        @apply bg-opacity-80 bg-[#F8F8FC] xl:p-3 lg:p-2 p-1
    }
    .admin-data-table.analyze tbody td {
        @apply odd:bg-opacity-80 odd:bg-[#F8F8FC] bg-white xl:p-6 lg:p-5 p-4
    }
    .upload-form .admin-data-table tbody td {
        @apply lg:first:px-6 lg:last:px-6 first:px-4 last:px-4
    }
    .admin-data-table tbody td:first-child {
        border-radius: 22px 0 0 22px;
    }
    .admin-data-table tbody td:last-child {
        border-radius: 0 22px 22px 0;
        @apply lg:min-w-[110px]
    }
    .admin-data-table tbody tr td {
        border-top: 5px solid #dedff1;
    }
    .admin-data-table thead  {
        position: sticky;top: 0;background: #dddfef;z-index: 10;
    }
    .btn-sm {
        @apply text-sm px-3 py-2 text-primary hover:bg-primary hover:text-white font-[500]
    }
    .admin-data-table .btn-sm {
        @apply xl:px-2 lg:px-[6px] py-1 px-1 text-xs
    }
    .btn-primary {
        @apply  bg-primary rounded-[10px] !text-white hover:bg-[#7466B6]
    }
    .btn-secondary {
        @apply  bg-secondary rounded-[10px] !text-white hover:!bg-[#32B78B] border-0
    }

    .btn-danger {
        @apply  bg-red-500 rounded-[10px] !text-white hover:!bg-red-200 border-0
    }

    .inner-white-box {
        @apply bg-white p-6 rounded-[22px] -mx-6 -mb-6 mt-6
    }
    .box-white label, .inner-white-box label, .top-bar label {
        @apply title-primary-base mr-4 inline-block w-[150px]
    }
    .checkbox-list input[type="checkbox"] {
        @apply w-0 h-0 absolute opacity-0
    }
    .checkbox-list input[type="checkbox"] + label {
        @apply before:content-[''] before:w-4 before:h-4 before:inline-block before:align-middle before:rounded-full before:bg-white before:mr-2 mb-4 !mr-0 before:shadow w-auto;
    }
    .checkbox-list input[type="checkbox"]:checked + label {
        @apply before:bg-secondary
    }
    .date-wrapper {
        @apply relative block after:content-[''] after:absolute after:w-5 after:h-[calc(100%-4px)] after:right-6 after:top-[2px]
    }
    .date-wrapper:after {
        background: #f3f3fa url(./images/date.svg) center no-repeat;
        background-size: contain;
        pointer-events: none;
    }
    .box-white .date-wrapper:after{background-color: #fff;}
    .date-wrapper input {
        @apply bg-transparent border-[1px] border-solid border-opacity-50 border-primary hover:border-opacity-100 lg:py-[11px] text-primary
    }
    .export-btn{
        @apply after:content-[''] after:inline-block after:align-middle after:w-3 after:h-4 after:ml-2
    }
    .export-btn:after{
        background:url(./images/export.svg) center no-repeat;
        background-size: contain;
    }
    .export-btn:hover:after{
        filter: brightness(0) invert(1);
    }
    .table-save-btn {
        @apply bg-[#79EE93] text-primary rounded-[22px] hover:bg-secondary text-sm py-1 px-2
    }
    .table-archive-btn {
        @apply bg-[#DC5757] text-white rounded-[22px] hover:bg-red-500 text-sm py-1 px-2
    }
    .box-primary.small-box {
        background: #3A2699 url(./images/bg-small-box.svg) top right no-repeat;
        background-size: contain;
    }
    .pill {
        @apply rounded-[22px] text-sm py-2 px-3 font-bold inline-block text-center text-white
    }
    .manager-scores {
        @apply block w-[85px] h-[85px] m-auto text-center leading-[85px] rounded-full relative z-0 font-bold;
    }
    .old-score {
        @apply rounded-full absolute border-[1px] border-dashed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-20;
    }
    .new-score {
        @apply rounded-full absolute border-[1px] border-solid top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-10 bg-opacity-20 min-w-[35px] min-h-[35px];
    }
    .table-managers-info {
        @apply fixed bottom-[-85px] right-8 bg-white rounded-t-[22px] p-6 text-primary text-sm min-w-[340px] max-w-full transition-all
    }
    .table-managers-info.show {
        @apply bottom-0
    }
    .table-managers-info h3:after, .table-managers-info button:after {
        @apply w-5 h-4 inline-block align-middle absolute right-0 content-[''] top-1;
        background: url(./images/arrow-primary.svg) center no-repeat;
    }
    .table-managers-info:not(.show) h3:after, .table-managers-info:not(.show) button:after {
        @apply -rotate-180
    }
    .table-managers-info .rounded-solid {
        @apply w-5 h-5 rounded-full border-[1px] border-solid inline-block align-middle
    }
    .table-managers-info .rounded-dashed {
        @apply w-5 h-5 rounded-full border-[1px] border-dashed inline-block align-middle
    }
    .admin-survey-tabs .survey-tab{
        @apply w-1/4 text-center text-[14px] font-bold even:left-[22%] bg-[#f2f3fa] -mb-3 pb-7 pt-4
    }
    .admin-survey-tabs .survey-tab.active{
        @apply bg-[#dedff1] text-primary pt-6
    }
    .graph-btn {
        @apply bg-[#F3F3FA] block mb-3 rounded-lg py-[10px] px-4 font-bold w-full text-left hover:bg-[#E1E1F1] transition-all disabled:opacity-50 disabled:pointer-events-none
    }
    .managers-list{
        @apply absolute top-1/2 left-full -translate-y-1/2 rounded-[22px] p-6 text-primary min-w-[240px] text-left hidden z-[1];
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), rgba(243, 243, 250, 0.80);
        backdrop-filter: blur(27.5px);
        font-family: Poppins, sans-serif;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.2);
    }
    .managers-list ul { 
        @apply list-disc underline leading-6 pl-3 overflow-auto max-h-[50vh] font-light
    }
    .managers-list .close-button {
        @apply w-4 h-4 bg-no-repeat bg-center;
        background-image: url('./images/close-primary.svg');
    }
    div:hover > .managers-list{
        @apply block
    }
    .lg\:w-1\/5 .managers-list{
     @apply left-auto right-full
    }
    .skeleton .box-primary.small-box{
        @apply !bg-[#F8F8FC] !bg-opacity-80;
        background-image: url(./images/bg-small-box-skeleton.svg);
    }
    .skeleton-text {
        @apply h-2.5 bg-[#d1d5db] rounded-full w-full mb-2
    }
    .skeleton-title {
        @apply h-3 bg-[#9ca3af] rounded-full w-2/3
    }
    .round-archive {
        @apply w-4 h-4 bg-primary absolute -top-1.5 right-1.5 rounded-full bg-center bg-no-repeat cursor-pointer;
        background-image: url(./images/close-white.svg);
        background-size: 8px;
    }
    .round-delete {
        @apply w-4 h-4 bg-red-500 absolute -top-1.5 right-1.5 rounded-full bg-center bg-no-repeat cursor-pointer;
        background-image: url(./images/close-white.svg);
        background-size: 8px;
    }
}

.slick-slide>div {
    margin: 3px 3px;
}

.disable-scroll {
    overflow: hidden;
  }
  
#root {
    white-space: pre-line;
}
.icon-leadership {
    background-image: url('./images/leadership.svg');
}
.icon-feedback {
    background-image: url('./images/feedback.svg');
}
.icon-data {
    background-image: url('./images/data.svg');
}
.icon-results {
    background-image: url('./images/results.svg');
}
.icon-clock {
    background-image: url('./images/clock.svg');
}

.icon-strength-weakness img, .icon-light-green:after, .icon-light-blue:after, .icon-light-brown:after, .icon-dark-purple:after, .icon-light-purple:after, .icon-dark-red:after, .icon-hot-orange:after, .icon-sea-blue:after {
    filter: brightness(0) invert(1);
}

.bg-light-green-light {
    background-color: #e4f5ef
}

.element-light-green, .modal-light-green {
    --category-color: #32B78B;
    --category-color-light: #e4f5ef;
}
.text-light-green, .text-light-green svg.MuiSvgIcon-root {
    color: #32B78B;
}
.element-light-green .expand-button, .element-light-green .survey-box-sub-category .subcategory-image, .icon-light-green, .bg-light-green {
    background-color: #32B78B!important;
}
/*.element-light-green .survey-image, .icon-light-green:after {
    background-image: url(./images/light-green.svg);
}*/
.icon-light-green {
    color: #32B78B;
}

.element-light-blue, .modal-light-blue{
    --category-color: #479CDA;
    --category-color-light: #E8F3FB;
}
.bg-light-blue-light{
    background-color: #E8F3FB
}
.text-light-blue, .text-light-blue svg.MuiSvgIcon-root {
    color: #479CDA
}
.element-light-blue .expand-button, .element-light-blue .survey-box-sub-categories .subcategory-image, .icon-light-blue, .bg-light-blue {
    background-color: #479CDA!important
}
/*.element-light-blue .survey-image, .icon-light-blue:after {
    background-image: url(./images/light-blue.svg);
}*/
.icon-light-blue{
    color: #479CDA;
}

.element-light-brown, .modal-light-brown{
    --category-color: #CE992F;
    --category-color-light: #fcf5e7;
}
.bg-light-brown-light{
    background-color: #fcf5e7
}
.text-light-brown, .text-light-brown svg.MuiSvgIcon-root {
    color: #CE992F
}
.element-light-brown .expand-button, .element-light-brown .survey-box-sub-categories .subcategory-image, .icon-light-brown, .bg-light-brown {
    background-color: #CE992F!important
}
/*.element-light-brown .survey-image, .icon-light-brown:after {
    background-image: url(./images/light-brown.svg);
}*/
.icon-light-brown{
    color: #CE992F;
}

.element-dark-purple, .modal-dark-purple{
    --category-color: #3A2699;
    --category-color-light: #EAE9F4;
}
.bg-dark-purple-light{
    background-color: #EAE9F4
}
.text-dark-purple, .text-dark-purple svg.MuiSvgIcon-root {
    color: #3A2699
}
.element-dark-purple .expand-button, .element-dark-purple .survey-box-sub-categories .subcategory-image, .icon-dark-purple, .bg-dark-purple {
    background-color: #3A2699!important
}
/*.element-dark-purple .survey-image, .icon-dark-purple:after {
    background-image: url(./images/dark-purple.svg);
}*/
.icon-dark-purple{
    color: #3A2699;
}

.element-light-purple, .modal-light-purple{
    --category-color: #7A4CC4;
    --category-color-light: #F1E9F8;
}
.bg-light-purple-light{
    background-color: #F1E9F8
}
.text-light-purple, .text-light-purple svg.MuiSvgIcon-root {
    color: #7A4CC4
}
.element-light-purple .expand-button, .element-light-purple .survey-box-sub-categories .subcategory-image, .icon-light-purple, .bg-light-purple {
    background-color: #7A4CC4!important
}
/*.element-light-purple .survey-image, .icon-light-purple:after {
    background-image: url(./images/light-purple.svg);
}*/
.icon-light-purple{
    color: #7A4CC4;
}
.element-dark-red, .modal-dark-red {
    --category-color: #E2376B;
    --category-color-light: #FCEBF1;
}
.bg-dark-red-light{
    background-color: #FCEBF1
}
.text-dark-red, .text-dark-red svg.MuiSvgIcon-root {
    color: #E2376B
}
.element-dark-red .expand-button, .element-dark-red .survey-box-sub-categories .subcategory-image, .icon-dark-red, .bg-dark-red {
    background-color: #E2376B!important
}
/*.element-dark-red .survey-image, .icon-dark-red:after {
    background-image: url(./images/dark-red.svg);
}*/
.icon-dark-red{
    color: #E2376B;
}

.bg-hot-orange-light {
    background-color: #fff2e8
}

.element-hot-orange, .modal-hot-orange {
    --category-color: #fd7e14;
    --category-color-light: #fff2e8;
}
.text-hot-orange, .text-hot-orange svg.MuiSvgIcon-root {
    color: #fd7e14;
}
.element-hot-orange .expand-button, .element-hot-orange .survey-box-sub-category .subcategory-image, .icon-hot-orange, .bg-hot-orange {
    background-color: #fd7e14!important;
}
.icon-hot-orange{
    color: #fd7e14;
}

.bg-sea-bluet-light {
    background-color: #dcedff
}

.element-sea-blue, .modal-sea-blue {
    --category-color: #007bff;
    --category-color-light: #dcedff;
}
.text-sea-blue, .text-sea-blue svg.MuiSvgIcon-root {
    color: #007bff;
}
.element-sea-blue .expand-button, .element-sea-blue .survey-box-sub-category .subcategory-image, .icon-sea-blue, .bg-sea-blue {
    background-color: #007bff!important;
}
.icon-sea-blue {
    color: #007bff;
}
.bg-small-dark {
    background-image: url(./images/bg-small-dark.svg);
    background-position: top right;
    background-repeat: no-repeat;
}
.bg-small-dark-2 {
    background-image: url(./images/bg-small-dark-2.svg);
    background-position: top right;
    background-repeat: no-repeat;
}
@media (min-width: 1024px) AND (max-height: 680px){
    .start-box h3 {margin-bottom: 30px;}
    .start-box a.button {margin-top: 50px;}
    .start-box {
        height: 80%;
        padding-top: 45px;
        padding-bottom: 45px;
    }
}


fieldset.MuiOutlinedInput-notchedOutline {
    border-color: rgba(58, 38, 153, 0.5);
}
.MuiInputBase-root:hover fieldset.MuiOutlinedInput-notchedOutline {
    border-color: rgba(58, 38, 153, 1)!important;
}
.MuiInputBase-root.MuiInputBase-formControl {
    border-radius: 10px;
}
div.MuiSelect-select {
    font-family: Poppins, sans-serif;
    padding: 11px 20px;
    font-size: 14px;
    color: #382597;
}
li.MuiButtonBase-root {
    font-family: Poppins, sans-serif;
}
svg.MuiSvgIcon-root {
    color: #382597;
}
div.MuiFormControl-root {margin: 0;}
.admin-data-table .MuiFormControl-root {
    width: 100%;
}
/* .admin-data-table.table-employees .MuiFormControl-root, .admin-data-table.table-managers .MuiFormControl-root {
    max-width: 20vh
} */
.admin-data-table div.MuiSelect-select {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
@media (min-width: 1024px){
    .admin-data-table div.MuiSelect-select {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
.status-orange {
    @apply inline-block text-opacity-70 text-[10px] rounded-[22px] bg-[#e19849] px-3 py-1 mt-6 text-center min-w-[65px] text-white relative;
}
.status-orange strong {
    @apply text-sm text-white align-middle
}
.status-red {
    @apply inline-block text-opacity-70 text-[10px] rounded-[22px] bg-[#DC5757] px-3 py-1 mt-6 text-center min-w-[65px] text-white relative;
}
.status-red strong {
    @apply text-sm text-white align-middle
}
.status-red:after {
    @apply absolute content-[''] left-full ml-2 w-4 h-4;
    background: url(./images/red-arrow.svg) center no-repeat;
}
.status-green {
    @apply inline-block text-opacity-70 text-[10px] rounded-[22px] bg-[#79EE93] px-3 py-1 mt-6 text-center min-w-[65px] text-primary;
}
 .status-green strong  {
    @apply text-sm text-primary align-middle
}
.overflow-auto::-webkit-scrollbar-thumb, .overflow-y-auto::-webkit-scrollbar-thumb {
    @apply bg-primary rounded-[22px]
}
.overflow-auto::-webkit-scrollbar, .overflow-y-auto::-webkit-scrollbar {
    background-color: transparent;
    width:4px;
    height: 4px;
}
.survey-status-red, .survey-status-green, .survey-status-yellow{
    @apply inline-block text-sm rounded-[22px] bg-[#DC5757] px-5 py-2 text-center min-w-[65px] text-white relative font-bold;
}
.survey-status-red svg.MuiSvgIcon-root, .survey-status-green svg.MuiSvgIcon-root, .survey-status-yellow svg.MuiSvgIcon-root{
    @apply text-white w-5 h-5 inline-block align-middle mr-2
}
.survey-status-green {
    @apply bg-dark_green
}
.survey-status-yellow {
    @apply bg-yellow-400
}
.managers-list ul::-webkit-scrollbar-thumb {
    @apply bg-[#DEDFF2] rounded-none
}
.managers-list ul::-webkit-scrollbar {
    background-color: #BCBDD0;
    width:5px;
    height: 5px;
}
@keyframes pulse-fade {
    0%{
        opacity: 0;
        transform: scale(0);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
.animate-pulse-fade {
    animation: pulse-fade 1s ;
}
@keyframes progress-bar {
    0%{
        width: 0;
    }
    100%{
        width: 100%;
    }
}
.animation-progress-bar {
    animation: progress-bar 2s linear infinite;
}